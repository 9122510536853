@import './../../styles/global.scss';

$size: 48px;

.logo {
  display: flex;
  flex-direction: row;
  padding: gux(1/3, $size);
  white-space: nowrap;

  &_icon {
    height: $size;
    padding-right: gux(1/4, $size);
    align-self: center;
  }

  &_text {
    font-size: $size;
    font-weight: bold;
    font-family: 'Caladea', serif;
    font-style: normal;
    letter-spacing: 0.4px;
    align-self: center;

    &_pool {
      color: $atlantic;
    }
  }

  &--stacked {
    .logo_icon {
      align-self: center;
      height: gux(2, $size);
    }
    .logo_text {
      display: flex;
      flex-direction: column;
    }
  }
}
