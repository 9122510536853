// Created by: Joel Klemens - March 27, 2022

@import './../../styles/global.scss';

.puf_progressbar {
  display: flex;
  justify-content: center;
  text-align: center;
  position: relative;

  &_title {
    margin: 0 gux(1) 0 gux(1);

    &--progress {
      margin: 0 gux(2);
    }
  }

  &_full {
    .puf_progressbar_bar_outer {
      width: 500px;
      border-radius: 40px;
      background-color: $atlantic;
      height: 20px;
      border: solid 5px;
      border-color: $shallow;
    }

    .puf_progressbar_bar_inner {
      height: 100%;
      border-radius: 40px;
      text-align: right;
      background-color: $pineapple;
      height: 20px;
    }
  }

  &_small {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: gux(15);
    height: 40px;
    border-radius: 40px;
    background-color: $shallow;
    justify-content: space-evenly;
    margin-top: 10px;

    .puf_progressbar_bar_outer {
      width: 25%;
      border-radius: 40px;
      background-color: $atlantic;
      height: 20px;
      border: solid 5px;
      border-color: $shallow;
      margin-right: gux(1);
    }

    .puf_progressbar_bar_inner {
      height: 100%;
      border-radius: 40px;
      text-align: right;
      background-color: $pineapple;
      height: 20px;
    }
  }

  &_achieved {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 gux(1);
    height: 40px;
    border-radius: 40px;
    background-color: $shallow;
    justify-content: space-evenly;
    margin-top: 10px;

    .puf_progressbar_checkmark {
      margin-right: gux(1);
      width: 18% !important;
    }
  }
}
