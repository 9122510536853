@import './../../../styles/global.scss';

.puf_hamburger {
  button > div > span {
    background: $charcoal !important;
  }

  // passing in a class doesn't let you override mui styles
  &_menu {
    width: 100% !important;
    max-width: unset !important;
    left: 0 !important;
    bottom: 0 !important;
    right: 0 !important;

    &_items {
      justify-content: center !important;
      font-family: 'Caladea' !important;
      font-size: 24px !important;
      padding-top: gux(1) !important;
      padding-bottom: gux(1) !important;
    }
  }
}
