@import './../../styles/global.scss';

@mixin responsive($size) {
  &_intro {
    width: gux(0.6, $size);
  }

  &_splash {
    width: $size;
  }
}

@mixin smallSplash() {
  &_splash--large,
  &_splash--medium {
    display: none;
  }
}

@mixin mediumSplash() {
  &_splash--large,
  &_splash--small {
    display: none;
  }
}

@mixin largeSplash() {
  &_splash--medium,
  &_splash--small {
    display: none;
  }
}

@mixin responsiveText($heading, $subheading) {
  &_intro {
    .puf_text--heading {
      font-size: $heading;
    }
    .puf_text--optimized {
      font-size: $subheading;
    }
    .puf_text {
      margin: gux(0.75) 0 !important;
    }
  }
}

body {
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

.home {
  @media (max-width: $mobile) {
    &_splash > img {
      width: gux(45);
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }

  @media (max-width: $foldable) {
    @include responsive(gux(40));
    @include smallSplash();
    @include responsiveText(36px, 30px);

    flex-direction: column-reverse;
    margin: gux(5) auto;
    gap: gux(3);

    &_intro,
    &_splash {
      margin: 0 auto;
    }
  }

  @media (min-width: $foldable) and (max-width: $tablet) {
    @include responsive(gux(50));
    @include smallSplash();
    @include responsiveText(36px, 30px);
  }

  @media (min-width: $tablet) and (max-width: $laptop) {
    @include responsive(gux(50));
    @include mediumSplash();
    @include responsiveText(36px, 30px);
    margin: gux(5) auto;
  }

  @media (min-width: $laptop) and (max-width: $desktop) {
    @include responsive(gux(70));
    @include mediumSplash();
    @include responsiveText(38px, 30px);
    margin: gux(10) auto;
  }

  @media (min-width: $desktop) {
    @include responsive(gux(100));
    @include largeSplash();
  }

  display: flex;
  justify-content: center;
  margin: gux(10) auto gux(15) auto;
  gap: gux(5);
  width: 100%;

  &_buttons {
    @include fadeIn($longFade);

    display: flex;
    flex-direction: row;
    gap: gux(2);
    margin-top: gux(1);
  }

  &_intro {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .puf_text {
      &--heading {
        @include fadeIn($shortFade);
      }

      &--optimized,
      &--body {
        @include fadeIn($longFade);
      }
      margin: gux(1) 0;
    }
    min-width: 300px;
    max-width: 600px;
  }

  &_splash {
    @include fadeIn();

    display: flex;
    flex-direction: column;
    justify-content: center;

    object-fit: cover;
    min-width: 300px;

    img {
      position: relative;
    }
  }
}
