$shortFade: 500ms;
$mediumFade: 1000ms;
$longFade: 1500ms;

// Colours
$atlantic: #002688;
$shallow: #183a93;
$fiesta: #ff3737;
$sunrise: #ff9737;
$pineapple: #eea20f;
$matte: #141414;
$charcoal: #333333;
$snowball: #fcfcfc;
$sky: #88a9fd;

// Screen sizes
$mobile: 479px;
$foldable: 539px;
$tablet: 767px;
$laptop: 1023px;
$desktop: 1199px;
$wide: 1439px;

@function gux($multiplier, $base: 8px) {
  @return $base * $multiplier;
}

@mixin fadeIn($waitTime: $mediumFade) {
  animation: wait #{$waitTime}, fade-in 800ms #{$waitTime};
}

@keyframes wait {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
