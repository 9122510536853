@import './../../styles/global.scss';

.puf_text {
  &--optimized {
    font-family: 'Caladea';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 37px;
  }

  &--heading {
    font-family: 'Caladea';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 48px;
  }

  &--subheading {
    font-family: 'Caladea';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 37px;
  }

  &--body {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
  }

  &--matte {
    color: $matte;
  }
  &--atlantic {
    color: $atlantic;
  }
  &--charcoal {
    color: $charcoal;
  }
  &--snowball {
    color: $snowball;
  }
  &--sky {
    color: $sky;
  }
}
