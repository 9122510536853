@import './../../styles/global.scss';

@mixin responsiveView($width: 100%, $maxWidth: auto) {
  width: $width;
  max-width: $maxWidth;
}

@mixin responsiveCard($width: 100%, $margin: 0 0 gux(1) 0) {
  &_steps_card {
    text-align: start;

    img {
      margin-bottom: gux(4);
      width: gux(0.7, $width);
    }

    .puf_text {
      width: gux(0.9, $width);
      margin: $margin;
    }

    .puf_heading {
      margin-top: gux(2);
    }
  }
}

@mixin columnView($gap: gux(5)) {
  &_steps {
    flex-direction: column !important;
    gap: gux(5);
  }
}

@mixin longCard($imgMin: gux(30)) {
  @include columnView();

  .puf_card {
    width: 100% !important;

    & > img {
      width: 30%;
      min-width: $imgMin;
    }

    &_text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: auto;
    }
  }
}

@mixin responsiveHeading($width: 100%, $margin: gux(20) auto gux(10)) {
  &_heading {
    display: flex;
    justify-content: center;
    width: $width;
    margin: $margin;
    text-align: start;
  }
}

@mixin responsiveBackground($height: 1000px) {
  height: $height;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.starting {
  @include responsiveBackground();
  @include responsiveHeading();

  @media (min-width: $wide) {
    @include responsiveView(90%, $desktop);
    @include responsiveCard();
  }

  @media (min-width: $desktop) and (max-width: $wide) {
    @include responsiveView(85%);
    @include responsiveCard();
  }

  @media (min-width: $laptop) and (max-width: $desktop) {
    @include responsiveView(85%);
    @include responsiveCard(80%, 0 auto);
  }

  @media (min-width: $tablet) and (max-width: $laptop) {
    @include responsiveView(90%);
    @include responsiveHeading(100%, gux(10) auto gux(7.5));
    @include responsiveBackground(gux(170));
    @include responsiveCard(100%, 0 0 gux(1) auto);
    @include longCard();
  }

  @media (min-width: $foldable) and (max-width: $tablet) {
    @include responsiveView(90%);
    @include responsiveHeading(100%, gux(10) auto gux(7.5));
    @include responsiveBackground(gux(150));
    @include responsiveCard(100%, 0 0 gux(1) auto);
    @include longCard(gux(25));
  }

  @media (max-width: $foldable) {
    @include responsiveView(90%);
    @include responsiveHeading(100%, gux(15) auto gux(7.5));
    @include responsiveBackground(gux(155));
    @include columnView();

    &_steps_card > img,
    .puf_card_hybrid_header > img {
      width: 40%;
    }

    &_heading {
      .puf_card_title {
        font-size: 12vw;
        line-height: 14vw;
      }
    }
    &_steps {
      .puf_card {
        justify-content: space-between;
        width: auto;

        &_hybrid {
          &_header {
            flex-direction: row;
            justify-content: space-between;
            gap: gux(2.5);
            text-align: center;
          }

          &_title,
          &_body {
            text-align: left;
            align-self: center;
          }

          &_body {
            margin-top: gux(1);
          }
        }

        &_title {
          font-size: 7vw;
          line-height: 7.5vw;
        }

        &_subtitle {
          font-size: 6vw;
          line-height: 6.5vw;
          margin-bottom: 0px;
        }
      }
    }
  }

  &_steps {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &_wallet {
    color: $atlantic;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: gux(0.1);
  }
}
