@import './../../styles/global.scss';

@mixin responsiveIntro($width: 100%, $maxWidth: auto, $gap: 15) {
  &_intro {
    width: $width;
    max-width: $maxWidth;
    gap: gux($gap);

    // using padding for top so scroll is accurate
    margin: 0 auto;
    padding-top: gux(13);
    padding-bottom: gux(8);
  }
}

@mixin responsiveHybridHeading($width: 100%, $maxWidth: auto, $gap: 15) {
  .puf_card_hybrid_header {
    width: $width;
    max-width: $maxWidth;
    gap: gux($gap);
  }
}

@mixin responsiveText($size) {
  .puf_card_title {
    font-size: $size;
    line-height: gux(1.2, $size);
  }
}

@mixin responsiveQuestions($width: 100%, $size: gux(4), $margin: gux(1)) {
  .puf_expandable {
    &_container {
      width: $width;
    }

    &_base {
      margin: $margin auto;
    }

    &_title_text {
      font-size: $size !important;
    }

    &_body_text {
      font-size: 0.625 * $size !important;
      margin-left: gux(0.5, $size) !important;
    }
  }
}

@mixin responsiveLogo($width) {
  &_intro > img,
  .puf_card_hybrid_header > img {
    width: $width;
  }
}

@mixin columnView() {
  .puf_card {
    flex-direction: column;
  }
}

.frequent_questions {
  @media (min-width: $wide) {
    @include responsiveIntro(90%, $desktop, 12);
    @include responsiveLogo(gux(38));
    @include responsiveQuestions(90%);
  }

  @media (min-width: $desktop) and (max-width: $wide) {
    @include responsiveIntro(90%, auto, 12);
    @include responsiveLogo(gux(38));
    @include responsiveQuestions(85%);
  }

  @media (min-width: $laptop) and (max-width: $desktop) {
    @include responsiveIntro(95%, auto, 10);
    @include responsiveLogo(gux(38));
    @include responsiveQuestions(90%);
  }

  @media (min-width: $tablet) and (max-width: $laptop) {
    @include responsiveIntro(90%, auto, 5);
    @include responsiveText(40px);
    @include responsiveLogo(gux(34));
    @include responsiveQuestions(90%);
  }

  @media (min-width: $foldable) and (max-width: $tablet) {
    @include responsiveIntro(90%, auto, 5);
    @include responsiveText(gux(4));
    @include responsiveLogo(gux(31));
    @include responsiveQuestions(90%, gux(3.5));
  }

  @media (max-width: $foldable) {
    @include responsiveIntro(90%, auto, 5);
    @include responsiveHybridHeading(100%, auto, 2.5);
    @include responsiveText(8vw);
    @include responsiveLogo(45vw);
    @include responsiveQuestions(90%, gux(3), gux(0));
    @include columnView();

    .puf_card_text,
    .puf_card_hybrid_header,
    .puf_card_hybrid_body {
      text-align: left;
    }

    .puf_card_hybrid_title {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &_intro {
      padding-top: gux(10);
      padding-bottom: gux(5);
    }
  }

  &_intro {
    justify-content: flex-start;

    .puf_card_text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
    }
  }

  &_contact,
  &_calculator {
    color: $atlantic;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: gux(0.1);
  }
}
