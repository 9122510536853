@import './../../styles/global.scss';

.puf_expandable {
  &_container {
    display: flex;
    justify-content: center;
    max-width: $desktop;
    margin: 0 auto;
  }

  &_base {
    width: 100%;
    margin: gux(1) auto;
    box-shadow: none !important;
  }

  &_title {
    display: flex !important;
    flex-direction: row-reverse !important;
    align-self: center;
    padding: 0 !important;

    &_text {
      margin-left: gux(2) !important;
      font-family: 'Caladea' !important;
      font-size: gux(4) !important;
    }
  }

  &_body_text {
    margin-left: gux(6) !important;
    font-family: 'Roboto' !important;
    font-size: gux(2.5) !important;
  }
}

svg {
  color: #ff9737 !important;
  margin-right: gux(1) !important;
  height: 32px !important;
  width: auto !important;
}

.MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(90deg) !important;
}
