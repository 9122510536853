@import './../../styles/global.scss';

.puf_card {
  display: flex;
  text-align: center;
  position: relative;

  width: 300px;

  img {
    align-self: center;
  }

  &_title {
    margin-top: gux(1);
    margin-bottom: gux(1);
  }

  &_subtitle,
  &_body {
    margin-top: gux(0);
  }

  &--row {
    flex-direction: row;
  }

  &--column,
  &--hybrid {
    flex-direction: column;
  }

  &_hybrid {
    &_header {
      display: flex;
      flex-direction: row;
    }
  }
}
