@import './../../styles/global.scss';

.puf_button {
  font-weight: 700 !important;
  text-transform: none !important;

  &--atlantic {
    color: white;
    background-color: $atlantic !important;
  }
  &--sunrise {
    color: black !important;
    border: 2px solid $sunrise !important;
  }
}
