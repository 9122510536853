@import './../../styles/global.scss';

@mixin responsiveCards($width, $start: 25%) {
  &_cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: absolute;
    margin: 0 auto;

    max-width: gux(3.5, $width);
    top: $start;

    &_entry {
      width: $width;
      padding-top: gux(5);
      margin: gux(5) auto;
      border: 4px solid $shallow;
      background-color: $atlantic;
      border-radius: gux(1);

      img {
        width: 60%;
      }
    }
  }
}

@mixin responsiveBackground($height: 1400px) {
  &_background {
    height: $height;
  }
}

@mixin responsiveText($size: 42px) {
  &_cards_entry {
    .puf_text {
      &--heading {
        font-size: $size;
        line-height: gux(1.1, $size);
      }
      &--subheading {
        font-size: gux(0.75, $size);
        line-height: gux(0.8, $size);
        margin-bottom: gux(0.5, $size);
      }
      &--body {
        font-size: gux(0.5, $size);
        line-height: gux(0.6, $size);
      }
    }
  }
}

.roadmap {
  display: flex;
  position: relative;
  justify-content: center;

  &_title {
    position: absolute;
    top: 0;
    width: 50%;
    min-width: 300px;
    margin: gux(15) auto;

    text-align: center;
  }

  @media (min-width: $wide) {
    @include responsiveBackground(gux(190));
    @include responsiveCards(gux(40), 19%);
    @include responsiveText(gux(4.2));
  }

  @media (min-width: $desktop) and (max-width: $wide) {
    @include responsiveBackground(gux(185));
    @include responsiveCards(gux(35), 21%);
    @include responsiveText(gux(4));
  }

  @media (min-width: $laptop) and (max-width: $desktop) {
    @include responsiveBackground(gux(170));
    @include responsiveCards(gux(28), 22%);
    @include responsiveText(gux(3.8));
  }

  @media (min-width: $tablet) and (max-width: $laptop) {
    @include responsiveBackground(gux(160));
    @include responsiveCards(gux(24), 24%);
    @include responsiveText(gux(3.2));
  }

  @media (min-width: $foldable) and (max-width: $tablet) {
    @include responsiveBackground(gux(240));
    @include responsiveCards(gux(32), 19%);
    @include responsiveText(gux(3.2));
  }

  @media (max-width: $foldable) {
    @include responsiveBackground(gux(115));
    @include responsiveCards(gux(33), 38%);
    @include responsiveText(gux(4));

    &_cards {
      max-width: 100%;
    }

    &_cards_entry {
      margin: gux(3) auto !important;

      img {
        width: 60% !important;
      }
    }

    &_title {
      width: 70%;
      min-width: 300px;
    }

    .control-dots {
      margin: gux(2) auto gux(0);
    }
  }
}
