@import './../../styles/global.scss';

@mixin responsiveCards($width, $start: 45%, $gap: gux(10)) {
  &_cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: absolute;
    margin: 0 auto;
    gap: $gap;

    max-width: gux(3.5, $width);
    top: $start;

    .puf_text {
      font-weight: normal !important;
    }

    &_entry {
      width: $width;
      margin: gux(5) auto;
    }
  }
}

@mixin responsiveBackground($height: 800px) {
  &_background {
    height: $height;
  }
}

@mixin responsiveText($size) {
}

.contact {
  display: flex;
  position: relative;
  justify-content: center;

  @media (min-width: $wide) {
    @include responsiveBackground();
    @include responsiveCards(360px, 45%, gux(25));
  }

  @media (min-width: $desktop) and (max-width: $wide) {
    @include responsiveBackground();
    @include responsiveCards(340px, 45%, gux(25));
  }

  @media (min-width: $laptop) and (max-width: $desktop) {
    @include responsiveBackground();
    @include responsiveCards(330px, 45%, gux(20));
  }

  @media (min-width: $tablet) and (max-width: $laptop) {
    @include responsiveBackground();
    @include responsiveCards(341px, 45%);
  }

  @media (min-width: $foldable) and (max-width: $tablet) {
    @include responsiveBackground(900px);
    @include responsiveCards(340px, 34%, gux(10));

    &_cards {
      flex-direction: column;
    }
  }

  @media (max-width: $foldable) {
    @include responsiveBackground(900px);
    @include responsiveCards(400px, 35%);

    &_cards_entry {
      margin: gux(3) auto !important;

      img {
        width: 50%;
      }

      h2 {
        font-size: 40px;
      }

      h3 {
        font-size: 25px;
      }
    }
  }

  &_title {
    position: absolute;
    top: 0;
    width: 35%;
    min-width: 300px;
    margin: gux(15) auto;

    text-align: center;
  }

  &_cards_entry_discord,
  &_cards_entry_email {
    .puf_text--heading {
      cursor: pointer;
    }

    .puf_text--body {
      margin-top: gux(4);
      font-size: gux(3);
      cursor: pointer;
    }

    img {
      cursor: pointer;
      width: gux(10);
      height: gux(12.5);
    }
  }
}
