@import './../../styles/global.scss';

@mixin responsiveImage($width: 80%) {
  &_card > img {
    width: $width;
  }
}

@mixin responsiveText($size) {
  .puf_text {
    &--heading {
      font-size: $size;
      line-height: gux(1.1, $size);
    }
    &--subheading {
      font-size: gux(0.75, $size);
      line-height: gux(1.1, gux(0.75, $size));
    }
  }
}

.not_found {
  display: flex;
  justify-content: center;
  position: absolute;
  inset: 0;

  &_card {
    width: 600px;
    justify-content: center;
  }

  @include responsiveImage();

  @media (max-width: $foldable) {
    @include responsiveImage(70%);
    @include responsiveText(gux(4.5));
  }

  @media (max-width: $mobile) {
    @include responsiveImage();
    @include responsiveText(gux(3.5));
  }
}
