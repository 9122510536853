@import './../../styles/global.scss';

@mixin responsiveToolbar($size: 48px) {
  &_logo {
    cursor: pointer;
    padding: gux(1/3, $size);

    .logo_icon {
      height: $size;
      padding-right: gux(1/4, $size);
    }

    .logo_text {
      font-size: $size;
    }
  }

  &_menu {
    padding: gux(2.5/5, $size) gux(0.5, $size);

    &_items {
      font-size: gux(1/2, $size);
      margin: auto gux(3/5, $size);

      &--selected {
        text-underline-offset: gux(1/4, $size);
      }
    }
  }
}

.toolbar {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.25);
  padding: gux(1);

  @media (min-width: $desktop) {
    @include responsiveToolbar();
  }

  @media (min-width: $laptop) and (max-width: $desktop) {
    @include responsiveToolbar(38px);
  }

  @media (min-width: $tablet) and (max-width: $laptop) {
    @include responsiveToolbar(32px);
  }

  @media (max-width: $tablet) {
    @include responsiveToolbar(28px);
  }

  &_logo_text {
    letter-spacing: 0.2px;
  }

  &_menu {
    display: flex;
    justify-content: center;

    &_items {
      white-space: nowrap;
      cursor: pointer;
      font-family: 'Caladea', serif;
      text-decoration-color: white;

      &--selected {
        text-decoration: underline;
        text-decoration-color: $fiesta;
        transition: text-decoration-color 350ms;
      }
    }
  }

  &_hamburger {
    align-self: center;
    margin-right: gux(1.5);
  }
}
